//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Mediaplayer from '../Partials/Mediaplayer/Mediaplayer.vue';
import TextBlock from '../Partials/TextBlock.vue';

export default {
    name: 'VideoTeaser',
    components: {TextBlock, Mediaplayer},
    props: ['data'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
            isMobile: !!this.$mobileDetect.mobile(),
        };
    },
    computed: {
        caption() {
            if (this.data?.video?.description) {
                return this.data?.video?.description;
            }

            return this.data?.caption;
        },
    },
};
